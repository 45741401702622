import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Button, InputAdornment, MenuItem, Stack, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { Select } from 'formik-mui'
import * as Yup from 'yup'

import {
  AssetBadge,
  ButtonContainer,
  ButtonsContainer,
  ErrorDisplay,
  Loading,
  NotificationConfirmation,
} from 'shared/components'
import { CurrencyField } from 'shared/forms'
import {
  MARKET_ASSETS_QUERY,
  NOTIFY_USER_REQUEST_MUTATION,
} from 'shared/queries'
import {
  setFormError,
  translateError,
} from 'shared/services'

import type { FormikProps } from 'formik'
import type {
  MarketAsset,
  MarketAssetsData,
  MarketAssetsVars,
  NotifyUserRequestData,
  NotifyUserRequestVars,
} from 'shared/queries'

type FormValues = {
  symbol: string
  amount: number
}

const initialValues =  ({
  symbol: '',
  amount: 0,
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    amount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .integer('Debes introducir un monto sin decimales'),
    symbol: Yup.string()
      .required('Este campo es obligatorio'),
  })

type InnerFormProps = FormikProps<FormValues> & {
  marketAssets: MarketAsset[]
}

const InnerForm = ({
  isSubmitting,
  isValid,
  submitForm,
  status,
  marketAssets,
}: InnerFormProps) => (
  <Form>
    <Stack
      spacing={2}
      marginTop={2}
    >
      <Field
        required
        name='symbol'
        type='text'
        label='Selecciona una criptomoneda'
        component={Select}
      >
        {marketAssets?.map((asset) => (
          <MenuItem
            key={asset.symbol}
            value={asset.symbol}
          >
            <AssetBadge
              symbol={asset.symbol}
              height={20}
              style={{ marginRight: 10 }}
            />
            {asset.name}
          </MenuItem>
        ))}
      </Field>
      <CurrencyField
        name='amount'
        label='Monto a comprar'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <small>CLP</small>&nbsp;$
            </InputAdornment>
          ),
        }}
        digits={0}
        positive
      />
    </Stack>
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={3}
    />
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          type='submit'
        >
          Confirmar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

export const PurchaseForm = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const [message, setMessage] = React.useState<string[]>([])

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const [notifyUserRequest] =
    useMutation<NotifyUserRequestData, NotifyUserRequestVars>(
      NOTIFY_USER_REQUEST_MUTATION, {
        errorPolicy: 'all',
      })

  if (assetsLoading) {
    return <Loading />
  }

  const handleSubmit = async (values: FormValues) => {
    const response = await notifyUserRequest({
      variables: {
        notificationType: 'PURCHASE',
        content: ['CLP$', values.amount.toString(), values.symbol],
      },
    })

    if (response.data?.notifyUserRequest) {
      setMessage(response.data.notifyUserRequest)
      setShowConfirmation(true)
      return
    }

    setFormError(formRef, translateError(response))
  }

  return showConfirmation ? (
    <NotificationConfirmation
      message={message}
      isPurchase
    />
  ) : (
    <React.Fragment>
      <Typography
        variant='h6'
        component='span'
        textAlign='center'
        gutterBottom
      >
        Comprar criptomonedas
      </Typography>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            {...props}
            marketAssets={assetsData?.marketAssets || []}
          />
        )}
      </Formik>
    </React.Fragment>
  )
}
