import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { WhatsApp } from '@mui/icons-material'
import {
  Button,
  Divider,
  InputAdornment,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  darken,
  lighten,
  useMediaQuery,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  Currency,
  CurrencyCell,
  ErrorDisplay,
  Loading,
  NotificationConfirmation,
  Table,
} from 'shared/components'
import { CurrencyField } from 'shared/forms'
import { BULK_PURCHASE_QUOTE_QUERY, NOTIFY_USER_REQUEST_MUTATION } from 'shared/queries'
import { numberFormatter, setFormError, translateError } from 'shared/services'

import { BankTransferDialog } from './bank_transfer_dialog'

import type { Theme } from '@mui/material'
import type { FormikProps } from 'formik'
import type {
  BulkPurchaseConfig,
  BulkPurchaseQuoteData,
  BulkPurchaseQuoteVars,
  NotifyUserRequestData,
  NotifyUserRequestVars,
} from 'shared/queries'

const phoneNumber = '56912345678'
const message = (amount: number) => '[Netastract] Hola Equipo comercial! Quiero realizar una' +
                                    `compra, me gustaría cotizar ${amount} CLP`

const ConfigDisclaimer = ({ config }: { config: BulkPurchaseConfig }) => (
  <Paper
    variant='outlined'
    sx={{ overflow: 'hidden' }}
  >
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>Límites de compra instantánea</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Monto mínimo</TableCell>
          <CurrencyCell
            currency='CLP'
            digits={0}
            value={config.minAmount}
          />
        </TableRow>
        <TableRow>
          <TableCell>Monto máximo</TableCell>
          <CurrencyCell
            currency='CLP'
            digits={0}
            value={config.maxAmount}
          />
        </TableRow>
        <TableRow>
          <TableCell>Máximo diario</TableCell>
          <CurrencyCell
            currency='CLP'
            digits={0}
            value={config.dailyMaxAmount}
          />
        </TableRow>
        <TableRow>
          <TableCell>Límite diario disponible</TableCell>
          <CurrencyCell
            currency='CLP'
            digits={0}
            value={config.dailyRemainingAmount}
          />
        </TableRow>
      </TableBody>
    </Table>
  </Paper>
)

const formatCurrency = (amount: number) => numberFormatter(0).format(amount)

type LargeAmountDisplayProps = {
  currency: string
  value: number
  text: string
}

const LargeAmountDisplay = ({
  currency,
  value,
  text,
}: LargeAmountDisplayProps) => (
  <Stack width='50%'>
    <Typography
      variant='h4'
      component='p'
      fontWeight={600}
      lineHeight='1.2'
      textAlign='center'
    >
      <Currency
        currency={currency}
        digits={2}
        value={value}
      />
    </Typography>
    <Typography
      variant='h6'
      component='p'
      textAlign='center'
    >
      {text}
    </Typography>
  </Stack>
)

type FormValues = {
  inAmount: number
}

const initialValues: FormValues = ({
  inAmount: 0,
})

const validationSchema = (config: BulkPurchaseConfig): Yup.SchemaOf<FormValues> => (
  Yup.object().shape({
    inAmount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .integer('Debes introducir un monto sin decimales')
      .min(config.minAmount,
        `Debes ingresar un monto mayor a ${formatCurrency(config.minAmount)}`),
  })
)

type InnerFormProps = FormikProps<FormValues> & {
  config: BulkPurchaseConfig
  quotePrice: number
}

const InnerForm = ({
  isSubmitting,
  status,
  submitForm,
  values,
  config,
  quotePrice,
}: InnerFormProps) => {
  const isOnLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const disablePurchaseButton = (
    values.inAmount > config.maxAmount || values.inAmount > config.dailyRemainingAmount
  )

  return (
    <Form>
      <Stack spacing={3}>
        <Typography
          variant='h6'
          textAlign='center'
        >
          Crear orden de compra
        </Typography>
        <ConfigDisclaimer config={config} />
        <CurrencyField
          name='inAmount'
          label='Monto a comprar'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <small>CLP</small>&nbsp;$
              </InputAdornment>
            ),
          }}
          digits={0}
          positive
        />
        <Paper
          variant='outlined'
          sx={(theme) => ({
            p: 2,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: theme.palette.mode === 'light'
              ? lighten(theme.palette.info.light, 0.8)
              : darken(theme.palette.info.light, 0.6),
            borderColor: theme.palette.mode === 'light'
              ? darken(theme.palette.info.light, 0.6)
              : theme.palette.info.light,
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
            },
          })}
        >
          <LargeAmountDisplay
            currency='CLP'
            value={quotePrice}
            text='Precio actual'
          />
          <Divider
            flexItem
            orientation={isOnLargeScreen ? 'vertical' : 'horizontal'}
          />
          <LargeAmountDisplay
            currency='USD'
            value={values.inAmount ? (values.inAmount / quotePrice) : 0}
            text='Monto a recibir'
          />
        </Paper>
        <BankTransferDialog />
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={3}
      />
      <ButtonsContainer sx={{ mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            target='_blank'
            href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message(values.inAmount))}`}
            rel='noopener noreferrer'
            size='large'
            variant='contained'
            fullWidth
            disabled={isSubmitting || !disablePurchaseButton}
            startIcon={<WhatsApp />}
          >
            Cotizar
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting || disablePurchaseButton}
            onClick={submitForm}
            variant='contained'
            color='warning'
          >
            COMPRAR
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type BulkPurchaseCreatorProps = {
  config: BulkPurchaseConfig
}

const BulkPurchaseCreator = ({
  config,
}: BulkPurchaseCreatorProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const [message, setMessage] = React.useState<string[]>([])

  const { loading, data, refetch } =
    useQuery<BulkPurchaseQuoteData, BulkPurchaseQuoteVars>(
      BULK_PURCHASE_QUOTE_QUERY, {
        fetchPolicy: 'network-only',
      })

  const [notifyUserRequest] =
    useMutation<NotifyUserRequestData, NotifyUserRequestVars>(
      NOTIFY_USER_REQUEST_MUTATION, {
        errorPolicy: 'all',
      })

  const quote = data?.bulkPurchaseQuote

  const handleSubmit = async (values: FormValues) => {
    const response = await notifyUserRequest({
      variables: {
        notificationType: 'BULK_PURCHASE',
        content: [values.inAmount.toString(), quote!.price.toString()],
      },
    })

    if (response.data?.notifyUserRequest) {
      setMessage(response.data.notifyUserRequest)
      setShowConfirmation(true)
      return
    }

    setFormError(formRef, translateError(response))
  }

  React.useEffect(() => {
    const bulkPurchaseQuoteLoop = setInterval(() => {
      refetch()
    }, 1000)

    return () => clearInterval(bulkPurchaseQuoteLoop)
  }, [refetch])

  if (loading || !quote) {
    return <Loading />
  }

  return showConfirmation ? (
    <NotificationConfirmation
      message={message}
      isPurchase
    />
  ) : (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema(config)}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            {...props}
            config={config}
            quotePrice={quote.price}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}

export default BulkPurchaseCreator
